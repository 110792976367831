const resolveStatusVariant = status => {
  if (status === 'draft') return 'primary'
  if (status === 'active') return 'success'
  if (status === 'inactive' || status === 'semiinactive') return 'secondary'
  if (status === 'archived') return 'default'
  if (status === 'trashed') return 'error'

  return 'primary'
}

export default {
  resolveStatusVariant,
}
