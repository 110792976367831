<template>
  <div id="tender-list">
    <v-card>
      <StandardCardHeader
        :go-to-link="'admin-company-create'"
        :title="'Company list'"
        :button-text="'Create'"
        :show-action-button="false"
        :link-button-color="'success'"
      />
      <v-row class="px-2 ma-0 mt-3">
        <v-col
          cols="12"
          sm="3"
        >
          <v-text-field
            v-model="searchQuery"
            :placeholder="$t('Type to search')"
            clearable
            outlined
            hide-details
            dense
            class="tender-search mb-4"
            @keyup="getDataFromApi"
            @click:clear.prevent="resetSearch()"
          >
          </v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="3"
        >
          <v-select
            v-model="statusFilter"
            :placeholder="$t('Status filter')"
            :items="statusOptions"
            item-value="key"
            :item-text="item => $t(item.name)"
            outlined
            dense
            clearable
            hide-details
            @change="getDataFromApi"
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          sm="3"
        >
          <v-checkbox
            v-model="clientFilter"
            :label="$t('Only clients')"
            outlined
            dense
            hide-details
            @change="getDataFromApi"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-data-table
        v-model="selectedRows"
        dense
        :headers="tableColumns"
        :items="companyListTable"
        :items-per-page="getItemsPerPageFromLS()"
        :options.sync="options"
        :server-items-length="totalCompanies"
        :loading="loading"
        :no-results-text="$t('noResults')"
        :no-data-text="$t('noResults')"
        :footer-props="{
          'items-per-page-text': '#',
          'items-per-page-options':[10, 20, 50, 100],
          'page-text': `{0}-{1} ${$t('of')} {2}`
        }"
      >
        <template #[`item.email`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'admin-company-edit', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.email }}
              </router-link>
            </div>
          </div>
        </template>

        <template #[`item.name`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'admin-company-edit', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.name }}
              </router-link>
            </div>
          </div>
        </template>

        <template #[`item.phone`]="{item}">
          <div class="d-flex align-center">
            <span class="text-capitalize">{{ item.phone }}</span>
          </div>
        </template>

        <template #[`item.company_type`]="{item}">
          <div class="d-flex align-center">
            <span class="text-capitalize">{{ item.company_type }}</span>
          </div>
        </template>

        <template #[`item.is_client`]="{item}">
          <div class="d-flex align-center">
            <span class="text-capitalize">{{ item.is_client ? $t('Yes') : $t('No') }}</span>
          </div>
        </template>

        <template #[`item.status`]="{item}">
          <v-chip
            small
            class="v-chip-light-bg font-weight-semibold text-capitalize"
            :color="resolveStatusVariant(item.status)"
            :class="`${resolveStatusVariant(item.status)}--text`"
          >
            {{ $t(item.status) }}
          </v-chip>
        </template>

        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :to="{name:'admin-company-edit',params: {id: item.id}}"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentEditOutline }}
                  </v-icon>
                  <span>{{ $t('Edit') }}</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="!item.deleted_at"
                link
              >
                <v-list-item-title @click.prevent="deleteCompany(item.id)">
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>{{ $t('Delete') }}</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.deleted_at"
                link
              >
                <v-list-item-title @click.prevent="restoreCompany(item.id)">
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileRestoreOutline }}
                  </v-icon>
                  <span>{{ $t('restore') }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiSquareEditOutline,
  mdiFileDocumentEditOutline,
  mdiFileRestoreOutline,
} from '@mdi/js'

// sidebar
import axiosIns from '@axios'
import statusService from '@/views/shared/statusService'
import StandardCardHeader from '@/components/shared/StandardCardHeader.vue'
import searchMixin from '@/mixins/searchMixin'
import { getItemsPerPageFromLS } from '@/services/localStorageService'

export default {
  components: { StandardCardHeader },
  mixins: [searchMixin],
  data() {
    const selectedRows = []
    const companyListTable = []
    const totalCompanies = 0
    const loading = true
    const options = {}

    const tableColumns = [
      {
        text: this.$t('Name'),
        value: 'name',
      },
      {
        text: this.$t('Email'),
        value: 'email',
      },
      {
        text: this.$t('Phone'),
        value: 'phone',
      },
      {
        text: this.$t('Referent'),
        value: 'referent_name',
      },
      {
        text: this.$t('Address'),
        value: 'address',
      },
      {
        text: this.$t('Zip code'),
        value: 'zip_code',
      },
      {
        text: this.$t('City'),
        value: 'city',
      },
      {
        text: this.$t('Client'),
        value: 'is_client',
      },
      {
        text: this.$t('Actions'),
        value: 'actions',
        align: 'center',
        sortable: true,
      },
    ]

    const companyTypeFilter = ''
    const statusFilter = ''
    const statusOptions = this.$store.state.baseStatuses
    const clientFilter = ''

    return {
      options,
      loading,
      companyTypeFilter,
      statusFilter,
      clientFilter,
      tableColumns,
      companyListTable,
      selectedRows,
      totalCompanies,
      statusOptions,
      icons: {
        mdiFileDocumentEditOutline,
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiFileRestoreOutline,
      },
    }
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },

  created() {
    this.resolveStatusVariant = statusService.resolveStatusVariant.bind(this)
  },

  methods: {
    getItemsPerPageFromLS,
    getDataFromApi() {
      localStorage.setItem('itemsPerPage', `${this.options.itemsPerPage}`)
      this.loading = true
      const orderBy = this.options.sortBy.length > 0 ? this.options.sortBy[0] : ''
      const orderDirection = this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : ''
      const search = this.searchQuery ?? ''
      const status = this.statusFilter ?? ''
      const companyType = this.companyTypeFilter ?? ''
      const isClient = this.clientFilter ?? ''
      axiosIns.get(`/admin/companies?page=${this.options.page}&perPage=
      ${this.options.itemsPerPage}&orderBy=${orderBy}&orderDirection=${orderDirection}
      &search=${search}&status=${status}&type=${companyType}&isClient=${isClient}`)
        .then(res => {
          this.totalCompanies = res.data.companies.total
          this.companyListTable = res.data.companies.data
          this.loading = false
        })
        .catch(() => {})
    },
    deleteCompany(id) {
      axiosIns.delete(`/admin/companies/${id}`)
        .then(() => {
          this.getDataFromApi()
        })
        .catch(() => {})
    },
    restoreCompany(id) {
      axiosIns.put(`/admin/companies/${id}/restore`)
        .then(() => {
          this.getDataFromApi()
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss">
</style>
